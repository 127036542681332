import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Navbar from "../components/Navbar"
import SEO from "../components/SEO"

import useTranslation from "../translations/translate"
import clsx from "clsx"

const TradeFair = ({ data, pageContext: { pageName } }) => {
  const { t } = useTranslation()

  const eventViews = data.allPublicEvent.nodes.map(event => {
    return (
      <div key={event.name} className="col-12 col-md-6 col-lg-4 col-xl-3">
        <div className="bg-light border rounded shadow h-100 d-flex flex-column">
          {event.localImage && (
            <div className="w-100">
              <GatsbyImage
                image={getImage(event.localImage)}
                alt={event.name}
                className="w-100 rounded-top"
              />
            </div>
          )}

          <div className="p-5 h-100 d-flex flex-column">
            <h2 className="mb-auto">{event.name}</h2>
            <p className="small fw-bold mt-5 mb-0">Start</p>
            <p>{new Date(event.start.replace(" ", "T")).toLocaleString()}</p>

            <p className="small fw-bold mb-0">End</p>
            <p>{new Date(event.end.replace(" ", "T")).toLocaleString()}</p>

            <p className="small fw-bold mb-0">Location</p>
            <p className={clsx(!event.description && "mb-0")}>
              {event.location}
            </p>

            {event.description && <p className="mb-0">{event.description}</p>}
          </div>
        </div>
      </div>
    )
  })

  return (
    <>
      <Navbar />
      <SEO
        title={t("page.tradeFairs.title")}
        description={t("page.tradeFairs.description")}
        pageName={pageName}
      />
      <section className="container content">
        <div className="row g-5">{eventViews}</div>
      </section>
    </>
  )
}

export const query = graphql`
  query Query {
    allPublicEvent(sort: { fields: start, order: ASC }) {
      nodes {
        name
        start
        end
        location
        description
        localImage {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`

export default TradeFair
